<template>
  <div>
    <el-dialog title="上传微课"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="600px"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="100px">
        <!-- <video src=""></video> -->
        <el-row>
          <el-col :span="24">
            <el-form-item label="选择微课">
              <el-button type="primary"
                         v-if="fileList.length == 0"
                         @click="checkFile">选择微课</el-button>
              <div v-else>
                <el-tag closable
                        @close="clear"
                        type="primary">
                  {{fileList[0].name}}
                </el-tag>
                <uploadProgress ref="uploadProgressRef"
                                @handleClose="handleClose"
                                :url="url" />
              </div>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="描述">
              <el-input clearable
                        placeholder="请输入描述"
                        v-model="form.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row style="text-align:center">
        <el-button type="primary"
                   @click="save">确 认</el-button>
      </el-row>
      <video id="videoPlayerNew"
             :src="videoUrl"
             v-show="false"
             controls></video>
    </el-dialog>
  </div>
</template>

<script>
import uploadProgress from '@/components/uploadPregress/index.vue'

export default {
  data () {
    return {
      form: {},
      dialogVisible: false,
      fileList: [],
      url: '',
      videoUrl: ''
    }
  },
  components: {
    uploadProgress
  },
  methods: {
    handleClose () {
      this.url = ''
      this.dialogVisible = false
      this.fileList = []
      this.$parent.refresh()
    },
    success () {
      this.handleClose()
      this.$parent.search
    },
    save () {
      if (this.fileList.length == 0) {
        this.$message.warning('请选择微课')
        return
      }
      var vid = document.getElementById("videoPlayerNew");
      let formData = new FormData()
      formData.append('length', vid.duration)//打印时长
      formData.append('video', this.fileList[0])
      // 指定上传文件的字段名称
      formData.append('filename', 'video')
      formData.append('question_id', this.form.question_id)
      formData.append('description', this.form.description)
      formData.append('task_id', this.form.task_id)


      let form = {
        length: vid.duration,
        filename: 'video',
        question_id: this.form.question_id,
        description: this.form.description,
        task_id: this.form.task_id,
      }
      formData.append('sign', this.$getMd5Form(form))
      this.$refs.uploadProgressRef.upFile(formData)

      // this.$http({
      //   url: this.url,
      //   method: 'post',
      //   Headers: {
      //     'Content-Type': 'multipart/form-data'
      //   },
      //   data: formData
      // }).then(result => {
      //   this.$notify({
      //     title: '提示',
      //     message: '上传成功!',
      //     type: 'success'
      //   });
      //   this.handleClose()
      //   this.$parent.search()
      // })
    },
    checkFile () {
      this.$get_file({ limit: 100, fileType: 'video/mp4,video/3gpp,video/mpeg,video/ogg' }).then(res => {
        this.fileList.push(res)
        this.videoUrl = URL.createObjectURL(res)
      })
    },
    clear () {
      this.fileList = []
    }
  }
}
</script>

<style>
</style>